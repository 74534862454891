<template>
    <div class="entities" v-loading="isLoading">
        {{ entityTypeComputed }} afectate:
        <ul>
            <li v-for="(item, j) in entities" :key="j">
                <template v-if="item.url != null">
                    <a :href="item.url" target="_blank">{{ item.name }} <i class="el-icon-link"></i> </a>
                </template>
                <template v-else>
                    <span>{{ item.name }}</span>
                </template>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    props: ['restrictionId'],
    data() {
        return {
            entityType: null,
            entities: [],
            isLoading: false,
        };
    },
    methods: {
        async getRestrictionEntities() {
            this.isLoading = true;
            try {
                const data = await this.$store.dispatch('user/GetRestrictionEntities', this.restrictionId);
                this.entities = data.message?.list;
                this.entityType = data.message?.entity;
            } catch (err) {
                console.log(err);
                return [];
            } finally {
                this.isLoading = false;
            }
        },
    },
    computed: {
        entityTypeComputed() {
            switch (this.entityType) {
                case 'product':
                    return 'Produse';

                case 'category':
                    return 'Categorii';

                default:
                    return 'Resurse';
            }
        },
    },
    mounted() {
        return this.getRestrictionEntities();
    },
};
</script>
<style lang="scss" scoped>
.entities {
    font-size: 14px;
    ul {
        margin: 5px;
        overflow: hidden;
        max-height: 10em;

        &:hover {
            overflow-y: scroll;
        }

        li {
            color: #fca80e;
            line-height: 1.4;
            font-weight: 400;
        }
    }
}
</style>
